import { useEffect, useRef } from "react";
import { Tour } from "../../common-types";
import "./TourJSON.css";

export default function(props: {
    tour: Tour
}) {
    const {tour} = props;
    const tourJSON = JSON.stringify(tour, null, 2);
    const textareaRef = useRef();
    useEffect(() => {
        if(textareaRef.current) {
            const elem = textareaRef.current as HTMLTextAreaElement;
            elem.style.height = elem.scrollHeight + "px";
        }
    }, [tourJSON]);
    return (
        <div className="tour-includes-panel">
            <div className="tour-includes-inner">
                <textarea readOnly={true} ref={textareaRef} className="tour-json" value={tourJSON} />
            </div>
        </div>
    )
}
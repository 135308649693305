import { useQuill } from "react-quilljs";
import { Tour } from "../../common-types";
import { useEffect, useRef, useState } from "react";
import 'quill/dist/quill.snow.css';
import './TourDetails.css';

export default function(props: {
    getCloned: () => Tour,
    setTour: (t: Tour) => void,
    tour: Tour
}) {
    const {getCloned, setTour, tour} = props;
    const [isRaw, setIsRaw] = useState(false);
    const {quill, quillRef} = useQuill();
    const textareaRef = useRef<HTMLTextAreaElement>();
    useEffect(() => {
        if(quill) {
            quill.clipboard.dangerouslyPasteHTML(tour.details);
            quill.on('text-change', (delta, oldDelta, source) => {
                const newHTML = quill.getSemanticHTML();
                const newTour = getCloned();
                newTour.details = newHTML;
                setTour(newTour);
            });
        }

    }, [quill]);
    const onRawnessChange = (newIsRaw: boolean) => {
        if(isRaw === newIsRaw) return;
        const isFromRawToVisual = isRaw && !newIsRaw;
        if(isFromRawToVisual)
        {
            quill.deleteText(0, quill.getLength());
            quill.clipboard.dangerouslyPasteHTML(tour.details);
        }
        else
        {
            const newHTML = quill.getSemanticHTML();
            textareaRef.current.value = newHTML;
        }
        setIsRaw(newIsRaw);
    }
    const panelClass = ["tour-details-panel", isRaw ? "raw" : "visual"].join(" ");
    const visualBtnClass = ["btn", "visual", isRaw ? "" : "active"].join(" ");
    const rawBtnClass = ["btn", "raw", isRaw ? "active" : ""].join(" ");
    return (
        <div className={panelClass}>
            <div className="toggle-btns">
                <button onClick={() => onRawnessChange(false)} className={visualBtnClass}>Görsel Editör</button>
                <button onClick={() => onRawnessChange(true)} className={rawBtnClass}>HTML</button>
            </div>
            <textarea onInput={() => {
                const newTour = getCloned();
                newTour.details = textareaRef.current.value;
                setTour(newTour);
            }} ref={textareaRef} className="tour-details" />
            <div ref={quillRef} />
        </div>
    )
}
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../../hooks/useApi";
import Ticket from "../../components/ticket/Ticket";
import "./TicketsContainer.css";
import useCommon from "../../hooks/useCommon";
import { ApiErrorResult, TicketStatusType } from "../../common-types";
import { ModeratorTicketListEntry } from "../../moderator-types";

export default function(props: {
    ticketType: "pending" | "assigned" | "completed",
    handleTicketEdit: (ticketId: string) => void
}) {
    const api = useApi();
    const common = useCommon();
    const res = useQuery(
        {
            queryKey: ["all-tickets", props.ticketType],
            queryFn: async () => {
                const timeoutId = setTimeout(() => {
                    common.setLoading(true);
                }, 500);
                let result = props.ticketType == "assigned" ? await api.getAssignedTickets() : await api.getTicketList();
                const asEntries = result as ModeratorTicketListEntry[];
                const asError = result as ApiErrorResult;
                if(!asError.isError)
                {
                    result = asEntries.filter(e => {
                        const isCompleted = [TicketStatusType.Finished, TicketStatusType.CancelledModerator, TicketStatusType.CancelledUser].includes(e.entry.status.type);
                        const filterCompleted = props.ticketType === "completed";
                        return isCompleted === filterCompleted;
                    })
                }
                clearTimeout(timeoutId);
                common.setLoading(false);
                return result;
            },
            refetchOnWindowFocus: true
        }
    )
    const isLoading = res.isLoading;
    const ticketsResponse = res.data ?? {
        isError: true,
        message: "Loading"
    }
    const tickets = (ticketsResponse as ApiErrorResult).isError ? [] : (ticketsResponse as ModeratorTicketListEntry[]);
    tickets.sort((a, b) => {
        const aDate = new Date(a.entry.creationDate);
        const bDate = new Date(b.entry.creationDate);
        return bDate.getTime() -  aDate.getTime();
    });
    return (
        <div className="tickets-container">
            {isLoading && <div>Loading...</div>}
            {(res.isFetched && (!tickets || tickets.length === 0)) && <div>Burası boş :(</div>}
            {(res.isFetched && !!tickets) && tickets.map((td) => {
                return (<Ticket
                    onClick={() => props.handleTicketEdit(td.entry.id)}
                    assigned={td.assignment !== "none"}
                    assignee={td.assignedTo}
                    assignedSelf={td.assignment === "self"}
                    ticketListEntry={td.entry}
                    key={td.entry.id}
                    refreshHandler={() => res.refetch()}
                />)
            })}
        </div>
    )
}